body {
  margin: 0;
  font-family: 'Monserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Gotham', sans-serif;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Fuentes para títulos y subtítulos */

@font-face {
  font-family: 'Gotham';
  src: url('./assets/Fuentes_tipografia/TitulosySubtitulos/Gotham-Font/Gotham-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/Fuentes_tipografia/TitulosySubtitulos/Gotham-Font/Gotham-Light.otf');
  font-weight: normal;
  font-style: normal;
}

/* Fuentes para párrafos */

@font-face {
  font-family: 'Monserrat';
  src: url('./assets/Fuentes_tipografia/Parrafos/Montserrat/Montserrat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monserrat';
  src: url('./assets/Fuentes_tipografia/Parrafos/Montserrat/Montserrat-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}